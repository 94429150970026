'use client';
import React, { useEffect } from 'react';
// import InputBase from '@mui/material/InputBase';
import { SxProps } from '@mui/system';
import { Box } from '@mui/material';
import Icon from '../Icon';
import Button from '../ButtonComponent';
import { colors } from '@/lib/theme/palette';
import DebounceInput from '../DebounceInput';

// import theme from '@/lib/theme/theme';
const buttonsDefaultStyles: SxProps = {
  width: {
    xs: '32px',
    md: '40px',
    xl: '52px',
  },
  height: {
    xs: '32px',
    md: '40px',
    xl: '52px',
  },
};

const iconDefaultStyles: SxProps = {
  width: {
    xs: '16px',
    md: '24px',
    xl: '28px',
  },
  height: {
    xs: '18px',
    md: '24px',
    xl: '28px',
  },
};

const buttonsHeaderStyles: SxProps = {
  width: {
    xs: '36px',
    xl: '48px',
  },
  height: {
    xs: '36px',
    xl: '48px',
  },
};

const IconHeaderStyles: SxProps = {
  width: {
    xs: '16px',
    xl: '28px',
  },
  height: {
    xs: '16px',
    xl: '28px',
  },
};

const inputDefaultStyles: SxProps = {
  ml: 1,
  flex: 1,
  fontSize: {
    xs: '14px',
    sm: '15px',
    md: '15.5px',
    lg: '16px',
    xl: '20px',
  },
  fontWeight: {
    xs: 400,
    sm: 500,
  },

  '& > input': {
    padding: '0 !important',
    height: '32px',
    border: 'none !important',
    outline: 'none !important',
  },

  '& > fieldset': {
    border: 'none !important',
    outline: 'none !important',
  },

  '&::placeholder': {
    color: 'rgba(155, 168, 183, 1)',
  },
};

const inputHeaderStyles: SxProps = {
  ml: 1,
  flex: 1,
  fontSize: {
    xs: '14px',
    sm: '16px',
    xl: '18px',
  },
  fontWeight: {
    xs: 400,
    sm: 500,
  },

  '& > input': {
    padding: '0 !important',
    height: '32px',
    border: 'none !important',
    outline: 'none !important',
  },

  '& > fieldset': {
    border: 'none !important',
    outline: 'none !important',
  },

  borderColor: 'rgba(230, 233, 237, 1)',

  '&::placeholder': {
    color: 'rgba(155, 168, 183, 1)',
  },
};
interface SearchProps {
  value: string;
  sx?: SxProps;
  variant?: 'default' | 'mobile' | 'desktop' | 'header';
  setShowMenu: (show: boolean) => void;
  handleSearchFieldChange: (value: string) => void;
  handleSearch: () => void;
}

export default function SearchBox(props: SearchProps) {
  const buttonStyles: SxProps =
    props.variant === 'header' ? buttonsHeaderStyles : buttonsDefaultStyles;

  const iconStyles: SxProps =
    props.variant === 'header' ? IconHeaderStyles : iconDefaultStyles;

  const inputStyles: SxProps =
    props.variant === 'header' ? inputHeaderStyles : inputDefaultStyles;

  const { setShowMenu, handleSearchFieldChange, handleSearch, ...rest } = props;

  const [search, setSearch] = React.useState<string>('');

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleFocus = () => {
    setShowMenu(true);
  };

  useEffect(() => {
    setSearch(props.value);
  }, [props.value]);

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch();
      }}
      bgcolor="#fff"
      {...rest}
    >
      <DebounceInput
        value={search}
        fullWidth
        sx={inputStyles}
        placeholder="Enter resource, technology or company name"
        inputProps={{
          'aria-label': 'Enter resource, technology or company name',
        }}
        handleDebounce={handleSearchFieldChange}
        debounceTimeout={500}
        onInput={onSearch}
        onFocus={handleFocus}
      />
      <Button
        type="button"
        aria-label="search"
        variant={props.variant === 'header' ? 'outlined-primary' : 'filled'}
        onClick={handleSearch}
        sx={{
          borderRadius: '50%',
          border: props.variant === 'header' ? '1px solid #C9D3FF' : 'none',
          ...buttonStyles,
        }}
      >
        <Icon
          icon="search"
          color={props.variant === 'header' ? colors.blue : '#fff'}
          sx={iconStyles}
        />
      </Button>
    </Box>
  );
}
